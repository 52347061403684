import * as React from "react";
import {useTranslation} from "react-i18next";

interface IProgress {
    progress: number;
    uploadingMessage?: string;
}

const Process: React.FC<IProgress> = ({progress, uploadingMessage}) => {
    const {t} = useTranslation(['general']);

    return (
        <div
            className="flex flex-col justify-center items-center font-medium text-slate-900 px-4 rounded-2xl bg-white h-96 gap-8"
        >
            <div className="text-5xl md:text-7xl font-bold">
                {`${progress}%`}
            </div>
            <div className="text-center text-base md:text-lg font-medium">
                {uploadingMessage || t('overlays.upload.uploading', {ns: 'general'})}
            </div>
        </div>
    );
};

export default Process;
