export const prepareAccept = ({accept}: { accept?: string | string[]; }) => {
    if (accept) {
        let current = accept;
        let converted: { [k: string]: string[] } = {};

        if (!Array.isArray(accept)) current = [accept];

        for (const item of current) {
            switch (item) {
                case '*/*':
                    converted[item] = ['.*']
                    break;
                case 'application/pdf':
                    converted[item] = ['.pdf']
                    break;
                default:
                    throw new Error('Unknown mime type for upload');
            }
        }

        return converted;
    }

    return;
};

export const prepareFiles = (assets: File[]) => {
    let files = [];

    for (const asset of assets) {
        files.push({
            needUpload: true,
            isUploading: true,
            preview: URL.createObjectURL(asset),
            name: asset.name,
            data: asset,
        });
    }

    return files;
};
