import * as React from "react";
import {Dialog, DialogBackdrop, DialogPanel, Transition, TransitionChild} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/24/outline";
import Dropzone from "./components/Dropzone";
import {useFilesUploadStore} from "@sputnikestate/store";
import Process from "./components/Process";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {useShallow} from "zustand/react/shallow";

interface ContentProps {
    closeButtonRef: React.RefObject<HTMLButtonElement | null>;
}

const Content: React.FC<ContentProps> = ({closeButtonRef}) => {
    const {t} = useTranslation(['general']);

    const {
        accept,
        multiple,
        uploadMessage,
        uploadingMessage,
        successMessage,
        upload,
        state,
        files,
        startFileUpload,
        setFileUploaded,
        onUploadProgress,
        close,
    } = useFilesUploadStore(
        useShallow((state) => ({
            accept: state.accept,
            multiple: state.multiple,
            uploadMessage: state.uploadMessage,
            uploadingMessage: state.uploadingMessage,
            successMessage: state.successMessage,
            upload: state.upload,
            state: state.state,
            files: state.files,
            startFileUpload: state.startFileUpload,
            setFileUploaded: state.setFileUploaded,
            onUploadProgress: state.onUploadProgress,
            startUpload: state.startUpload,
            close: state.close,
        })),
    );

    const progress = React.useMemo(
        () => {
            if (files.length > 0) {
                const total = files.reduce((acc, file) => acc + file.data.size, 0);
                const loaded = files.reduce((acc, file) => acc + (file.progress?.loaded || 0), 0);
                return Math.round((loaded / total) * 100);
            }
            return 0;
        },
        [files]
    );

    const finished = React.useMemo(
        () => {
            return files.length && files.every(({isUploading}) => !isUploading);
        },
        [files]
    );

    React.useEffect(
        () => {
            if (files.length > 0) {
                for (let i = 0; i < files.length; i++) {
                    if (files[i].needUpload) {
                        upload({
                            startFileUpload,
                            setFileUploaded,
                            onUploadProgress,
                            index: i,
                            file: files[i],
                        });
                    }
                }
            }
        },
        [files]
    );

    React.useEffect(
        () => {
            if (finished) {
                close();
                toast.success(successMessage || t('overlays.upload.uploaded', {ns: 'general'}));
            }
        },
        [finished]
    );

    const handleClose = () => {
        if (state === 'uploading' && !finished) return;
        close();
    };

    return (

        <div className="relative flex flex-col pt-4 xl:pt-6 h-full">
            <div className="flex justify-between items-center px-4 xl:px-6 pt-0.5 z-10">
                <div className="text-2xl md:text-4xl text-slate-900 font-black tracking-tight">
                    {t('upload', {ns: 'general'})}
                </div>
                <button
                    type="button"
                    className="flex items-center justify-center h-10 w-10 rounded-full border border-transparent bg-cultured-100 hover:bg-cultured-150 focus:outline-hidden"
                    onClick={handleClose}
                    ref={closeButtonRef}
                >
                    <XMarkIcon className="h-6 w-6 text-blue-500"/>
                </button>
            </div>
            <div className="p-4 xl:p-6 h-full">
                {state === 'idle' && <Dropzone
                    accept={accept}
                    multiple={multiple}
                    uploadMessage={uploadMessage}
                />}
                {state === 'uploading' && <Process
                    progress={progress}
                    uploadingMessage={uploadingMessage}
                />}
            </div>
        </div>
    );
};

const Uploader: React.FC = () => {
    const closeButtonRef = React.useRef<HTMLButtonElement | null>(null);

    const {
        visible,
        state,
        close,
    } = useFilesUploadStore(
        useShallow((state) => ({
            visible: state.visible,
            state: state.state,
            close: state.close,
        })),
    );

    const handleClose = () => {
        if (state === 'uploading') return;
        close();
    };

    return (
        <Transition show={visible} as={React.Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={closeButtonRef} onClose={handleClose}>
                <DialogBackdrop
                    transition={true}
                    className="fixed inset-0 bg-black/40 transition duration-300 ease-out backdrop-blur-xs data-data-leave:ease-in data-data-leave:duration-200 data-closed:backdrop-blur-none data-closed:opacity-0"
                />
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end lg:items-center justify-center p-2 xl:p-4">
                        <TransitionChild
                            as={React.Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 lg:translate-y-0 lg:scale-95"
                            enterTo="opacity-100 translate-y-0 lg:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 lg:scale-100"
                            leaveTo="opacity-0 translate-y-4 lg:translate-y-0 lg:scale-95"
                        >
                            <DialogPanel
                                className="relative transform rounded-2xl bg-white text-left shadow-xl transition-all w-full lg:w-9/12 xl:w-3/6"
                            >
                                {visible && <Content closeButtonRef={closeButtonRef}/>}
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default Uploader;
