import * as React from "react";
import {useDropzone} from "react-dropzone";
import {twMerge} from "tailwind-merge";
import {PlusIcon} from "@heroicons/react/24/solid";
import {useFilesUploadStore} from "@sputnikestate/store";
import {useTranslation} from "react-i18next";
import {prepareAccept, prepareFiles} from "../../../../../../helpers/files";

interface IDropzone {
    accept?: string | string[];
    multiple?: boolean;
    uploadMessage?: string;
}

const Dropzone: React.FC<IDropzone> = ({accept, multiple, uploadMessage}) => {
    const {t} = useTranslation(['general']);

    const startUpload = useFilesUploadStore((state) => state.startUpload);

    const {getRootProps, getInputProps, isDragAccept} = useDropzone({
        accept: prepareAccept({accept}),
        multiple,
        onDrop: (files) => startUpload(prepareFiles(files))
    });

    return (
        <div
            className={twMerge(
                'border-2 border-dotted rounded-2xl flex-1 h-96',
                isDragAccept ? 'bg-cultured-100 border-blue-500' : 'bg-cultured-50 border-cultured-150'
            )}
            {...getRootProps()}
        >
            <div className="flex items-center justify-center h-full">
                <input {...getInputProps()} />
                <div className="flex flex-col items-center gap-8">
                    <div
                        className="text-blue-500 w-3/4 md:w-8/12 xl:w-8/12 px-4 md:px-0 text-center text-base md:text-lg font-medium"
                    >
                        {uploadMessage || t('overlays.upload.dragHere', {ns: 'general'})}
                    </div>
                    <div
                        className="flex justify-center items-center rounded-full h-11 w-11 md:h-12 md:w-12 bg-blue-500"
                    >
                        <PlusIcon className="h-8 w-8 text-white"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dropzone;
