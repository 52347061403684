import * as React from "react";
import {hydrateRoot} from "react-dom/client";
import {getLocaleState} from "@locale/helpers";
import {useSSR} from "react-i18next";
import {AccountProvider, useAccount} from "./hooks";
import {createRouter} from "@routes/index";
import ErrorFallback from "./components/composites/views/error";
import {CookiesProvider} from "react-cookie";
import {GraphProvider} from "@sputnikestate/graph";
import {client} from "./graph";
import {HelmetProvider} from "react-helmet-async";
import * as Sentry from "@sentry/react";
import {StartClient} from "@tanstack/react-start";

declare global {
    interface Window {
        [key: string]: any;
    }
}

if (import.meta.env.PROD) Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.MODE,
    release: import.meta.env.VITE_SENTRY_RELEASE
});

const container = document.getElementById('root') as HTMLDivElement;

const router = createRouter();

const {initialI18nStore, initialLanguage} = getLocaleState();

const Inner = () => {
    const {user} = useAccount();

    router.update({
        ...router.options,
        context: {
            ...router.options.context,
            user,
        }
    });

    return <StartClient router={router}/>;
};

const ServerRoot: React.FC = () => {
    useSSR(initialI18nStore, initialLanguage);
    return (
        <Sentry.ErrorBoundary fallback={({error}) => <ErrorFallback error={error}/>}>
            <CookiesProvider>
                <GraphProvider client={client}>
                    <HelmetProvider>
                        <AccountProvider>
                            <Inner/>
                        </AccountProvider>
                    </HelmetProvider>
                </GraphProvider>
            </CookiesProvider>
        </Sentry.ErrorBoundary>
    );
};

hydrateRoot(container, <ServerRoot/>);
