import * as React from "react";
import {createRootRouteWithContext, Outlet} from "@tanstack/react-router";
import {useTranslation} from "react-i18next";
import {BreakpointsProvider, UseUserReturn} from "../hooks";
import {Helmet} from "react-helmet-async";
import {AnalyticsProvider} from "../hooks/useAnalytics";
import {ListingProvider} from "@sputnikestate/store";
import Toaster from "../components/Toaster";
import Editor from "../components/composites/overlays/Editor/Overlay";
import CallActionModal from "../components/composites/Modals/CallActionModal";
import organizationJsonLd from "../components/json+ld/organization";
import Uploader from "../components/composites/overlays/Uploader";

const Login = React.lazy(() => import('../components/composites/overlays/Login'));

interface RouterContext {
    user: UseUserReturn['user'];
}

type RootSearch = {
    yclid?: string;
    sct?: string;
};

const RootComponent: React.FC = () => {
    const {t, i18n} = useTranslation('meta');

    return (
        <>
            <BreakpointsProvider>
                <Helmet>
                    <title>Спутник</title>
                    <meta
                        name="description"
                        content="Поможем найти дом, который вы полюбите, быстро и без комиссии."
                    />
                    <meta property="og:type" content="website"/>
                    <meta property="og:site_name" content={t('og.siteName')}/>
                    <meta property="og:image" content={'https://sputnik.estate/logo512.png'}/>
                </Helmet>
                <AnalyticsProvider>
                    <ListingProvider>
                        <Outlet/>
                        <Toaster/>
                        <Editor/>
                        <CallActionModal/>
                        <Uploader/>
                        <React.Suspense>
                            <Login/>
                        </React.Suspense>
                        {(organizationJsonLd as any)[i18n.language]}
                    </ListingProvider>
                </AnalyticsProvider>
            </BreakpointsProvider>
        </>
    )
};

export const Route = createRootRouteWithContext<RouterContext>()({
    validateSearch: (search: Record<string, unknown>): RootSearch => {
        return {
            yclid: search?.yclid ? String(search.yclid) : undefined,
            sct: search?.sct ? String(search.sct) : undefined,
        };
    },
    component: RootComponent,
});
