import {createRouter as createTanstackRouter} from "@tanstack/react-router";
import {routeTree} from "./routeTree.gen";
import {parse, stringify} from "qs";

export const createRouter = () => {
    return createTanstackRouter({
        routeTree,
        defaultPreload: 'intent',
        defaultStaleTime: 5000,
        scrollRestoration: true,
        pathParamsAllowedCharacters: ['@'],
        stringifySearch: (value) => {
            return stringify(value, {
                arrayFormat: 'comma',
                addQueryPrefix: true,
                skipNulls: true,
            });
        },
        parseSearch: (value) => {
            return parse(value, {comma: true, ignoreQueryPrefix: true});
        },
        context: {
            user: undefined!,
        },
        dehydrate: () => {
            return {};
        },
        hydrate: () => {
            const script = document.getElementById('router');
            if (script) script.remove();
        },
    });
}

declare module '@tanstack/react-router' {
    interface Register {
        router: ReturnType<typeof createRouter>
    }
}
